import {
  memo, ReactElement, useEffect, useMemo, useState,
} from 'react'
import { GridApi } from 'ag-grid-community'
import { Spin, Tooltip } from 'antd'
import cn from 'classnames'
import isNil from 'lodash/isNil'
import { observer } from 'mobx-react'

import CaretUpIcon from '@components/icons/CaretUp'
import ColumnSubmenu from '@components/table/ColumnSubmenu'
import { useTableManagementStore } from '@contexts/file-edit-context'
import CalculatedColumnHeader from './CalculatedColumnHeader'

interface SortableColumnHeaderProps {
  title: string | ReactElement
  isSortActive: boolean
  sortOrder: AgGridColumnSortModel['sort'] | null
  sortIndex?: number | null | undefined
  className?: string
  withIndex?: boolean
  loading?: boolean
  onClick?: (evt: React.MouseEvent) => void
  showColumnMenu?: (source: HTMLElement) => void
  api?: GridApi
  isMenuVisible?: boolean
  indexProperty?: IndexProperty
}

interface SortingIndexProps {
  isVisible: boolean
  index: number | null | undefined
}

const SortingIndex: React.FC<SortingIndexProps> = memo(({ index, isVisible }) => {
  const shownIndex = isNil(index) ? 1 : index + 1

  return (
    <Tooltip title="Press command and select another column to apply multi-sorting">
      <div className={cn('ml-1', 'w-1.5', { invisible: !isVisible })}>
        {shownIndex}
      </div>
    </Tooltip>
  )
})

SortingIndex.displayName = 'SortingIndex'

const SortableColumnHeader: React.FC<SortableColumnHeaderProps> = ({
  className,
  title,
  isSortActive,
  sortOrder,
  sortIndex,
  withIndex = false,
  loading = false,
  onClick,
  showColumnMenu,
  api,
  isMenuVisible,
  indexProperty,
}) => {
  const isSortIndexVisible = useMemo(() => !isNil(sortIndex) || !isNil(sortOrder), [sortIndex, sortOrder])

  const tableManagementStore = useTableManagementStore()
  const [popConfirmVisible, setPopConfirmVisible] = useState(false)

  useEffect(() => {
    setPopConfirmVisible(false)
  }, [tableManagementStore.hidenIndexProperties])

  return (
    <div
      className={cn('flex items-center justify-start', className)}
    >
      {indexProperty?.isCalculated && <CalculatedColumnHeader />}

      <span className={cn('mr-2 select-none truncate')}>
        {title}
      </span>

      <div
        className="flex items-center shrink-0"
        role="button"
        onClick={onClick}
      >
        <div>
          <CaretUpIcon className={cn('sorter-caret', { active: isSortActive && sortOrder === 'asc' })} />
          <CaretUpIcon className={cn('sorter-caret rotate-180', { active: isSortActive && sortOrder === 'desc' })} />
        </div>
        {withIndex && <SortingIndex isVisible={isSortIndexVisible} index={sortIndex} />}
      </div>

      {loading && <Spin className="leading-0 ml-2" size="small" />}

      <span className="ml-1">
        <ColumnSubmenu
          showColumnMenu={showColumnMenu}
          api={api}
          displayName={title}
          isMenuVisible={isMenuVisible}
          popConfirmVisible={popConfirmVisible}
          setPopConfirmVisible={setPopConfirmVisible}
        />
      </span>
    </div>
  )
}
export default observer(SortableColumnHeader)
