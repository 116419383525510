/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const Edit = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={cn('sdf-icon fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M19.1863 8.73226L15.4984 5.04438L17.2499 3.29289C17.6404 2.90237 18.2735 2.90237 18.6641 3.29289L20.9377 5.56656C21.3283 5.95709 21.3283 6.59025 20.9377 6.98078L19.1863 8.73226ZM3 21.024L7.30253 20.4093L17.7515 9.96033L14.0637 6.27245L3.61465 16.7215L3 21.024Z" />
  </svg>
)

export default Edit
