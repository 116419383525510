import { ReactNode, useMemo, useState } from 'react'

import KetcherModal from '@components/file/KetcherModal'
import KetcherContext from './ketcher-context'

const KetcherModalProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [modals, setModals] = useState<KetcherModal[]>([])
  const [activeId, setActiveId] = useState<string|null>(null)

  const canAddModal = useMemo(() => {
    const isIdDuplicate = (id: string) => modals.some(modal => modal.id === id)
    const isMaxModalLimitReached = (id: string) => modals.length >= 5
      && id !== 'structureSearchPanelKetcher'
      && id !== 'addRow'
      && !modals.some(modal => modal.id === 'structureSearchPanelKetcher' || modal.id === 'addRow')
    const isModalOverflow = modals.length > 5
    return (id: string) => (isIdDuplicate(id)
      || isMaxModalLimitReached(id)
      || isModalOverflow
      || (id === undefined || id === null || id === ''))
  }, [modals])

  const addModal = ({
    id, structure, onOk, onCancel,
  }: AddKetcherModalProps) => {
    setActiveId(id)
    setModals(prevModals => ([
      ...prevModals,
      {
        id,
        structure,
        visible: true,
        onOk,
        onCancel,
      },
    ]))
  }

  const removeModal = (id: string) => {
    setModals(prevModals => {
      const filtered = prevModals.filter(modal => modal.id !== id)

      if (activeId === id && filtered.length) {
        setActiveId(filtered[filtered.length - 1].id)
      }

      return filtered
    })
  }

  const clearModals = () => {
    setModals([])
    setActiveId(null)
  }

  const onCancel = (modal: KetcherModal) => {
    modal.onCancel()

    if (activeId === modal.id) {
      const filtered = modals.filter(x => x.id !== modal.id)
      setActiveId(filtered[filtered.length - 1]?.id ?? null)
    }
  }

  return (
    <KetcherContext.Provider value={{
      modals, addModal, removeModal, canAddModal, clearModals,
    }}
    >
      {children}
      {modals.map((modal, idx) => (
        <KetcherModal
          offsetMultiplier={idx}
          key={modal.id}
          visible={modal.visible}
          initialStructure={modal.structure || ''}
          onOk={newMolStruct => {
            modal.onOk(newMolStruct)
          }}
          isActive={modal.id === activeId}
          setIsActive={() => {
            setActiveId(modal.id)
          }}
          onCancel={() => onCancel(modal)}
        />
      ))}
    </KetcherContext.Provider>
  )
}

export default KetcherModalProvider
