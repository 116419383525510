import { cloneDeep, isEqual } from 'lodash'
import {
  makeAutoObservable, runInAction,
} from 'mobx'

import { defaultSearchParams, SystemProperties } from '@shared/constants'
import apiService, { isAuthError } from '@shared/services/api-service'
import eventBusService from '@shared/services/event-bus-service'
import { createSignalAbortablePolling } from '@shared/services/polling'
import { getRenamedIndexPropertiesWithMap, getRenamedSearchParams } from '@utils/file-store-utils'
import { fetchFileDescription } from '@utils/http/files'
import {
  bulkDeleteMolecules as bulkDeleteMoleculeHttp,
  bulkIndexSearch,
  bulkIndexSearchNext,
  fetchIndexProperties as fetchIndexPropertiesHttp,
  updateIndexProperties as updateIndexPropertiesHttp,
  updateMolecule, updateMolecules,
} from '@utils/http/index'

const defaultSelectedMoleculeCustomOrders: SelectedMoleculeSerialsState = {
  wasAllSelectedButtonPressed: false,
  included: [],
}

export default class FileStore {
  private readonly _defaultSearchParams = defaultSearchParams

  private _isFileDescriptionFetching = true
  private _isMoleculesUpdating = false
  private _fileDescription?: UploadedFile
  private _stat?: IndexSearchResultStat
  private _searchId?: string
  private _loadedRowsCount = 0
  private _lastRow = 0
  private _isIndexPropertiesFetching = true
  private _isIndexPropertiesUpdating = false
  private _queryStructure = ''
  private _searchParams: IndexSearchParams = this.defaultSearchParams
  private _indexProperties: IndexProperty[] = []
  private _readOnly = false
  private _selectedDataForExport: RangeRowData[] = []
  private _structureSearchAbortController = new AbortController()
  private _matched = 0
  private _isSearchInProgress = false

  private _isStructureSearchVisible = false
  private _selectedMoleculeIdsState: SelectedMoleculeSerialsState =
    defaultSelectedMoleculeCustomOrders

  constructor(
    private readonly _fileId: string,
  ) {
    makeAutoObservable(this)

    this._searchParams = {
      ...this._searchParams,
      fileIds: [_fileId],
    }

    this.refetchFileDescription = this.refetchFileDescription.bind(this)
  }

  async setFileDescription(): Promise<void> {
    try {
      this._isFileDescriptionFetching = true
      const fileDescription = await fetchFileDescription({ id: this._fileId })

      runInAction(() => {
        this._fileDescription = fileDescription
      })
    } catch (error) {
      if (isAuthError(error)) return

      throw error
    } finally {
      runInAction(() => {
        this._isFileDescriptionFetching = false
      })
    }
  }

  async refetchFileDescription(): Promise<void> {
    const fileDescription = await fetchFileDescription({ id: this._fileId })

    runInAction(() => {
      this._fileDescription = fileDescription
    })
  }

  async fetchIndexProperties(): Promise<void> {
    try {
      this._isIndexPropertiesFetching = true
      const indexProperties = await fetchIndexPropertiesHttp({ id: this._fileId })

      runInAction(() => {
        this._indexProperties = indexProperties
      })
    } catch (error) {
      if (isAuthError(error)) return

      throw error
    } finally {
      runInAction(() => {
        this._isIndexPropertiesFetching = false
      })
    }
  }

  async updateIndexProperties(indexProperties: IndexProperty[]): Promise<void> {
    const [renamedIndexProperties, newOldNameMap] = getRenamedIndexPropertiesWithMap(indexProperties)
    const renamedSearchParams = getRenamedSearchParams(this.searchParams, newOldNameMap)

    runInAction(() => {
      this._searchParams = renamedSearchParams
      this._indexProperties = renamedIndexProperties
    })

    try {
      this._isIndexPropertiesUpdating = true

      await updateIndexPropertiesHttp(this._fileId, indexProperties)
      this._isIndexPropertiesUpdating = false

      this.searchMolecules(renamedSearchParams)
    } finally {
      runInAction(() => {
        this._isIndexPropertiesUpdating = false
      })
    }
  }

  async fetchUiSettings(): Promise<void> {
    const {
      queryStructure,
      isStructureSearchVisible,
      searchParams,
    } = await apiService.get<FileUiSettings>(`/ui/settings/${this._fileId}`)

    runInAction(() => {
      if (queryStructure && this._queryStructure !== queryStructure) {
        this._queryStructure = queryStructure
      }

      if (
        typeof isStructureSearchVisible === 'boolean'
        && this._isStructureSearchVisible !== isStructureSearchVisible
      ) {
        this._isStructureSearchVisible = isStructureSearchVisible
      }

      if (searchParams && !isEqual(this._searchParams, searchParams)) {
        this._searchParams = { ...searchParams, fileIds: this.searchParams.fileIds }
        eventBusService.emit('file:search')
      }
    })
  }

  async updateUiSettings(newSettings: FileUiSettings): Promise<void> {
    await apiService.put(`/ui/settings/${this._fileId}`, {
      headers: {
        'Content-Type': apiService.contentTypePlainText,
      },
      json: {
        queryStructure: this.queryStructure,
        isStructureSearchVisible: this.isStructureSearchVisible,
        searchParams: this.searchParams,
        ...newSettings,
      },
    })
  }

  async updateSorting(sorting: IndexSortingParams[]): Promise<void> {
    this._searchParams = {
      ...this._searchParams,
      sorting,
    }

    this.updateUiSettings({ searchParams: this.searchParams })
  }

  async searchMolecules(searchParams: IndexSearchParams): Promise<void> {
    this._searchParams = searchParams
    this.updateUiSettings({ searchParams })
    eventBusService.emit('file:search')
  }

  async renderMatchedMolecules(searchParams: IndexSearchParams): Promise<void> {
    const preparedSearchParams = {
      ...searchParams,
      fileIds: [searchParams.fileIds[0]],
      limit: 200,
    }
    this.isSearchInProgress = true
    this.matched = 0
    const searchResponse = await bulkIndexSearch(
      preparedSearchParams,
      {
        abortController: this._structureSearchAbortController,
      },
    )

    await createSignalAbortablePolling(
      this._structureSearchAbortController.signal,
      {
        action: bulkIndexSearchNext,
        getActionParams: () => [{
          searchIds: [searchResponse[this.fileId].searchId],
          abortController: this._structureSearchAbortController,
        }],
        options: {
          interval: 3000,
          validate: responseData => {
            this.matched = responseData[searchResponse[this.fileId].searchId].stat.matched
            this.isSearchInProgress = responseData[searchResponse[this.fileId].searchId].stat.inprogress
            return !responseData[searchResponse[this.fileId].searchId].stat.inprogress
          },
        },
      },
    )
  }

  repeatSearchMolecules = (waitFor?: Promise<void>): void => {
    eventBusService.emit('file:search', waitFor)
  }

  abortStructureSearch(): void {
    this._structureSearchAbortController.abort()
    this._structureSearchAbortController = new AbortController()
  }

  async updateMoleculePropertyValue(
    mol: Pick<MoleculeWithProperties, 'id' | 'customOrder' | 'structure'>,
    key: string,
    value: MolpropertyValue | null,
  ): Promise<void> {
    await updateMolecule(this.fileId, {
      id: mol.id,
      customOrder: mol.customOrder,
      structure: mol.structure,
      molproperties: {
        [key]: value,
      },
    })
  }

  async updateMoleculesPropertyValues(
    moleculeChanges: MoleculeChangesRequest[],
  ): Promise<void> {
    await updateMolecules(this.fileId, moleculeChanges)
  }

  async updateMoleculeStructure(
    mol: Pick<MoleculeRow, 'id' | 'customOrder'>,
    structure: string,
    formulas: string[],
  ): Promise<void> {
    const result = await updateMolecule(this.fileId, {
      id: mol.id,
      customOrder: mol.customOrder,
      structure,
      molproperties: {},
      formulas,
    })
    return result
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async bulkDeleteMolecules(onSuccess?: () => void, onError?: (error: any) => void): Promise<void> {
    this._isMoleculesUpdating = true

    try {
      const bulkDeleteTask = await bulkDeleteMoleculeHttp(
        this._fileId,
        this.selectedMoleculesParams,
      )

      apiService.createPollingRequest<TaskProgress>({
        url: `/task/${bulkDeleteTask.taskId}/status`,
        options: {},
        validate: ({ status }: TaskProgress) => status !== 'IN_PROGRESS',
      })
        .then(() => {
          eventBusService.emit('file:delete-molecules')
          onSuccess?.()
        })
        .catch(error => {
          onError?.(error)
        })
        .finally(() => {
          runInAction(() => {
            this._isMoleculesUpdating = false
          })
        })
    } catch (error) {
      onError?.(error)
    }
  }

  get fileMetaDataFetching(): boolean {
    return this._isFileDescriptionFetching || this._isIndexPropertiesFetching
  }

  get fileId(): string {
    return this._fileId
  }

  toggleIsStructureSearchVisible = (): void => {
    this._isStructureSearchVisible = !this._isStructureSearchVisible

    this.updateUiSettings({
      isStructureSearchVisible: this._isStructureSearchVisible,
    })
  }

  setAllSelected = (value: boolean): void => {
    if (value) {
      this._selectedMoleculeIdsState = {
        wasAllSelectedButtonPressed: true,
        excluded: [],
      }
    } else {
      this.clearAllSelectedDataForExport()
      this._selectedMoleculeIdsState = {
        wasAllSelectedButtonPressed: false,
        included: [],
      }
    }
  }

  setSelectedDataForExport(data: RangeRowData): RangeRowData[] {
    this._selectedDataForExport.push(data)

    return this._selectedDataForExport
  }

  setAllSelectedDataForExport(data: RangeRowData[]): RangeRowData[] {
    this._selectedDataForExport = data

    return this._selectedDataForExport
  }

  removeSelectedDataForExportById(id: string): RangeRowData[] {
    this._selectedDataForExport = this._selectedDataForExport.filter(item => item.id !== id)

    return this._selectedDataForExport
  }

  get allSelectedDataForExport(): RangeRowData[] {
    return this._selectedDataForExport
  }

  clearAllSelectedDataForExport(): RangeRowData[] {
    this._selectedDataForExport = []
    return this._selectedDataForExport
  }

  updateSelection = (id: string, isSelected: boolean): void => {
    if (this._selectedMoleculeIdsState.wasAllSelectedButtonPressed && isSelected) {
      this._selectedMoleculeIdsState.excluded = this._selectedMoleculeIdsState
        .excluded.filter(s => s !== id)
    } else if (this._selectedMoleculeIdsState.wasAllSelectedButtonPressed && !isSelected) {
      this._selectedMoleculeIdsState.excluded.push(id)
    } else if (!this._selectedMoleculeIdsState.wasAllSelectedButtonPressed) {
      this._selectedMoleculeIdsState.included = isSelected
        ? [...this._selectedMoleculeIdsState.included, id]
        : this._selectedMoleculeIdsState.included.filter((s => s !== id))
    }
  }

  getIsMoleculeSelected = (
    id: string,
    selectedMoleculeidsState = this._selectedMoleculeIdsState,
  ):
    boolean => (selectedMoleculeidsState.wasAllSelectedButtonPressed
    ? selectedMoleculeidsState.excluded.indexOf(id) === -1
    : selectedMoleculeidsState.included.indexOf(id) !== -1)

  get isAllSelected(): boolean {
    if (this._selectedMoleculeIdsState.wasAllSelectedButtonPressed) {
      return !this._selectedMoleculeIdsState.excluded.length
    }

    const includedIdsLength = this._selectedMoleculeIdsState.included.length
    return includedIdsLength !== 0 && includedIdsLength === this.lastRow
  }

  get isAddingColumn(): boolean {
    return this.indexProperties.some(
      indexProperty => indexProperty.status === 'pending',
    )
  }

  get isAddingColumnStatusExist(): boolean {
    return this.indexProperties.some(
      indexProperty => indexProperty.status,
    )
  }

  get indexPropertiesWithoutNewColumn(): IndexProperty[] {
    return this.indexProperties.filter(
      indexProperty => !indexProperty.status,
    )
  }

  deleteNewColumn = (): void => {
    this.indexProperties = this.indexPropertiesWithoutNewColumn
  }

  get isFileDescriptionFetching(): boolean {
    return this._isFileDescriptionFetching
  }

  get isMoleculesUpdating(): boolean {
    return this._isMoleculesUpdating
  }

  set isMoleculesUpdating(isUpdating: boolean) {
    this._isMoleculesUpdating = isUpdating
  }

  get fileDescription(): UploadedFile | undefined {
    return this._fileDescription
  }

  get queryStructure(): string {
    return this._queryStructure
  }

  set queryStructure(queryStructure: string) {
    if (this._queryStructure === queryStructure) return
    this._queryStructure = queryStructure
    this.updateUiSettings({ queryStructure })
  }

  get searchParams(): IndexSearchParams {
    return this._searchParams
  }

  set searchParams(searchParams: IndexSearchParams) {
    this._searchParams = searchParams
  }

  get indexProperties(): IndexProperty[] {
    return this._indexProperties
  }

  set indexProperties(indexProperties: IndexProperty[]) {
    this._indexProperties = indexProperties
  }

  get extendedProperties(): IndexProperty[] {
    return [
      ...SystemProperties,
      ...this.indexProperties,
    ]
  }

  get extendedPropertyTypesMap(): Map<string, string> {
    return new Map(this.extendedProperties.map(prop => [prop.name, prop.type]))
  }

  get extendedPropertiesVisible(): IndexProperty[] {
    return this.extendedProperties.filter(column => !column.hidden)
  }

  get extendedPropertiesVisibleCount(): number {
    return this.extendedPropertiesVisible.length
  }

  get isIndexPropertiesUpdating(): boolean {
    return this._isIndexPropertiesUpdating
  }

  get isIndexPropertiesFetching(): boolean {
    return this._isIndexPropertiesFetching
  }

  set isIndexPropertiesFetching(isFetching: boolean) {
    this._isIndexPropertiesFetching = isFetching
  }

  get selectedMoleculesParams(): SelectedMoleculesParams {
    if (this._selectedMoleculeIdsState.wasAllSelectedButtonPressed) {
      return {
        searchStructure: {
          ...this.searchParams,
          excludedMoleculeIds: this._selectedMoleculeIdsState.excluded,
        },

      }
    }
    return {
      moleculeIds: this._selectedMoleculeIdsState.included,
    }
  }

  get selectedMoleculeCustomOrdersState(): SelectedMoleculeSerialsState {
    return this._selectedMoleculeIdsState
  }

  get selectedMoleculeCustomOrdersCount(): number {
    if (this._selectedMoleculeIdsState.wasAllSelectedButtonPressed) {
      const excludedMoleculesCount = this._selectedMoleculeIdsState.excluded.length || 0
      const totalMoleculesCount = this.isSearchParamsDefault && this.fileDescription
        ? this.fileDescription.moleculesLibraryCount
        : this.searchStat?.matched || 0

      return totalMoleculesCount - excludedMoleculesCount
    }

    return this._selectedMoleculeIdsState.included.length
  }

  get hasSelectedRowCustomOrder(): boolean {
    return this.selectedMoleculeCustomOrdersCount > 0
  }

  get defaultSearchParams(): IndexSearchParams {
    return cloneDeep(this._defaultSearchParams)
  }

  get matched(): number {
    return this._matched
  }

  set matched(matched: number) {
    this._matched = matched
  }

  get isSearchInProgress(): boolean {
    return this._isSearchInProgress
  }

  set isSearchInProgress(progress: boolean) {
    this._isSearchInProgress = progress
  }

  get searchStat(): IndexSearchResultStat | undefined {
    return this._stat
  }

  set searchStat(stat: IndexSearchResultStat | undefined) {
    this._stat = stat
  }

  get searchId(): string | undefined {
    return this._searchId
  }

  set searchId(searchId: string | undefined) {
    this._searchId = searchId
  }

  get loadedRowsCount(): number {
    return this._loadedRowsCount
  }

  set loadedRowsCount(loadedRowsCount: number) {
    this._loadedRowsCount = loadedRowsCount
  }

  get lastRow(): number {
    return this._lastRow
  }

  set lastRow(lastRow: number) {
    this._lastRow = lastRow
  }

  get hasFilterResult(): boolean {
    return this.searchParams.type !== 'all' || !!this.searchParams.filters?.length
  }

  get isSearchParamsDefault(): boolean {
    return isEqual(this._searchParams, { ...this.defaultSearchParams, fileIds: [this._fileId] })
  }

  get isEmptyFile(): boolean {
    return this.fileDescription?.moleculesLibraryCount === 0
  }

  get isStructureSearchVisible(): boolean {
    return this._isStructureSearchVisible
  }

  set isStructureSearchVisible(isStructureSearchVisible: boolean) {
    if (this._isStructureSearchVisible === isStructureSearchVisible) return
    this._isStructureSearchVisible = isStructureSearchVisible
    this.updateUiSettings({ isStructureSearchVisible })
  }

  get readOnly(): boolean {
    return this._readOnly
  }

  set readOnly(readOnly: boolean) {
    this._readOnly = readOnly
  }
}
