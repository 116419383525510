import apiService from '@shared/services/api-service'

export default class IndigoHttpService {
  static async renderMolString(
    bodyRenderMol: IndigoRenderParams,
    {
      abortController,
    }: QueryOptionsWithAbortController = {},
  ): Promise<string> {
    return apiService.post('/v2/indigo/render', {
      headers: {
        'Content-Type': apiService.contentTypeSVG,
      },
      json: bodyRenderMol,
      signal: abortController?.signal,
    })
  }

  static async renderBulkMolString(
    requestBodyRenderMol: IndigoRenderParams[],
    {
      abortController,
    }: QueryOptionsWithAbortController = {},
  ): Promise<{ [key: string]: {error: string, result: string} }> {
    return apiService.post('/v2/indigo/render_bulk', {
      json: requestBodyRenderMol,
      signal: abortController?.signal,
    })
  }

  static async deserializeMolStruct(
    structure: string,
    {
      abortController,
    }: QueryOptionsWithAbortController = {},
  ): Promise<string> {
    return apiService.post<string>('/v2/indigo/deserialize', {
      headers: {
        'Content-Type': apiService.contentTypePlainText,
      },
      json: {
        struct: structure,
      },
      signal: abortController?.signal,
    })
  }
}
