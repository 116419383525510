/* eslint-disable max-len */
import '@assets/ag-grid.css'

import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import {
  BodyScrollEvent, CellValueChangedEvent, Column, GetContextMenuItemsParams, GridApi, GridReadyEvent,
  MenuItemDef,
  ModelUpdatedEvent, ProcessCellForExportParams, RowNode,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useDebounceFn, useSize } from 'ahooks'
import { message } from 'antd'
import cn from 'classnames'
import { debounce } from 'lodash'
import isNil from 'lodash/isNil'
import { observer } from 'mobx-react'
import { BookType } from 'xlsx'

import { subscribeBackgroundTask } from '@store/background-tasks'
import StructureImageStore from '@store/structure-image'
import FileLineNumberCell from '@components/file/FileLineNumberCell'
import FileLineNumberHeader from '@components/file/FileLineNumberHeader'
import AgGridColumnHeader from '@components/table/AgGridColumnHeader'
import AgGridNewColumnHeader from '@components/table/AgGridNewColumnHeader'
import NumericalCellEditor from '@components/table/NumericalCellEditor'
import StringCellEditor from '@components/table/StringCellEditor'
import StructureCellEditor from '@components/table/StructureCellEditor'
import StructureRenderer from '@components/table/StructureRenderer'
import useBackgroundTasksContext from '@contexts/background-tasks'
import { useAgGridApiService, useFileStore } from '@contexts/file-edit-context'
import FileEditorTableDataSourceWithForwardCache from '@datasource/FileEditorTableDataSourceWithForwardCache'
import {
  AG_GRID_TABLE_DEFAULT_SORTING_ORDER,
  AG_GRID_TABLE_ROW_HEIGHT_PX,
  AG_GRID_TABLE_SHOWING_OVERLAY_DELAY_MS,
} from '@shared/constants'
import useAgGridColDefs from '@shared/hooks/ag-grid/useAgGridColDefs'
import useAgGridThemeClassName from '@shared/hooks/ag-grid/useAgGridThemeClassName'
import useUnActualSearchParamsNotice from '@shared/hooks/useUnActualSearchParamsNotice'
import eventBusService from '@shared/services/event-bus-service'
import { getRowId } from '@utils/ag-grid-utils'
import { getRowsFromDataGrid } from '@utils/get-rows-from-grid'
import { prepareRowDataForExport } from '@utils/prepare-data-for-export'
import EmptyFileOverlay, { EmptyFileOverlayType } from './EmptyFileOverlay'

const AgFileEditorTable: React.FC<InnerTableProps> = observer(({ parentHeight }) => {
  const fileStore = useFileStore()
  const backgroundTasksStore = useBackgroundTasksContext()
  const agGridApiService = useAgGridApiService()
  const [gridApi, setGridApi] = useState<GridApi>()

  const datasource = useMemo(
    () => new FileEditorTableDataSourceWithForwardCache(fileStore),
    [fileStore],
  )

  const themeClassName = useAgGridThemeClassName()

  const onGridReady = useCallback((evt: GridReadyEvent) => {
    evt.columnApi.autoSizeColumns(['#'])
    setGridApi(evt.api)
  }, [])

  const noDataOverlayType = useMemo<EmptyFileOverlayType | undefined>(() => {
    if (fileStore.isEmptyFile) return 'empty-file'
    if (fileStore.searchStat?.inprogress === false && fileStore.searchStat?.filtered === 0) return 'no-found'
    return undefined
  }, [fileStore.isEmptyFile, fileStore.searchStat])

  useEffect(() => {
    if (!gridApi) return
    let tid: ReturnType<typeof setTimeout>

    if (noDataOverlayType) {
      // NOTE: we have to delay showing overlay call simply because AG Grid
      // hides this overlay on each parent (meaning the current) component render.
      // Such cases leads bugs like the next one:
      // https://quantori.atlassian.net/browse/SDFEDITOR-808?focusedCommentId=48356
      tid = setTimeout(() => gridApi.showNoRowsOverlay(), AG_GRID_TABLE_SHOWING_OVERLAY_DELAY_MS)
    } else {
      gridApi.hideOverlay()
    }

    // eslint-disable-next-line consistent-return
    return () => (tid) && clearTimeout(tid)
  })

  useEffect(() => {
    if (gridApi) agGridApiService.setGridApi(gridApi)

    return () => agGridApiService.reset()
  }, [agGridApiService, gridApi])

  const onUnActualSearchParamsNotice = useUnActualSearchParamsNotice({
    refreshCallback: () => {
      fileStore.renderMatchedMolecules(fileStore.searchParams)
      fileStore.repeatSearchMolecules()
    },
    disabled: fileStore.isSearchParamsDefault,
  })

  const gridRef = useRef<AgGridReact>(null)

  const pendingChanges = useRef<MoleculeChanges[]>([])

  const isDisabled = useMemo(
    () => Boolean(backgroundTasksStore.runningDataExportTasks.length),
    [backgroundTasksStore.runningDataExportTasks],
  )

  const addPendingChanges = (
    mol: MoleculeWithProperties,
    struct: string,
    field: MolpropertyValue,
  ) => {
    const currentPendingChanges = pendingChanges.current

    const existingMoleculeIndex = currentPendingChanges.findIndex(
      item => item.id === mol.id,
    )

    if (existingMoleculeIndex !== -1) {
      const existingMolecule = currentPendingChanges[existingMoleculeIndex]
      existingMolecule.properties[field] = struct
    } else {
      const newMolecule: MoleculeChanges = {
        id: mol.id,
        properties: {
          [field]: struct,
        },
        customOrder: mol.customOrder,
        structure: mol.structure,
      }
      currentPendingChanges.push(newMolecule)
    }

    pendingChanges.current = currentPendingChanges
  }

  const onSaveChanges = debounce(async () => {
    const regularArray = pendingChanges.current.map(item => ({ ...item }))

    if (regularArray.length === 1 && Object.keys(regularArray[0].properties).length === 1) {
      const [[key, value]] = Object.entries(regularArray[0].properties)
      fileStore.updateMoleculePropertyValue(regularArray[0], key, value)
      pendingChanges.current = []
      return
    }

    const requestArray = regularArray.map(item => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { structure, customOrder, ...rest } = item
      return rest
    })
    backgroundTasksStore.createTask({
      type: 'range-selection',
      data: {
        fileId: fileStore.fileId,
        fileName: fileStore.fileDescription!.fileName,
        requestData: requestArray,
      },
    })
    pendingChanges.current = []
  }, 100)

  const onCellNotUpdate = debounce(async () => {
    message.error('FAILED: Text cannot be inserted into number columns')
  }, 100)

  const onRangeSelectionChanged = useCallback(event => {
    const api = event?.api

    if (api) {
      const rangeStart = api.getCellRanges()[0]
      const structureIndex = rangeStart?.columns?.findIndex((column: { colId: string }) => column.colId === 'Structure' || column.colId === '#') ?? -1
      const startColumn = rangeStart?.startColumn?.colId
      const startIndex = rangeStart?.startRow?.rowIndex
      const endColumnIndex = structureIndex !== -1 ? structureIndex + 1 : 0
      const endColumn = rangeStart?.columns?.[endColumnIndex]?.colId
      const endIndex = rangeStart?.endRow?.rowIndex

      if (structureIndex !== -1) {
        api.clearFocusedCell()
        api.clearRangeSelection()
        api.addCellRange({
          rowStartIndex: startIndex,
          rowEndIndex: endIndex,
          columnStart: startColumn,
          columnEnd: endColumn,
        })
      }
    }
  }, [])

  const onCellValueChanged = useCallback(async (evt: CellValueChangedEvent) => {
    const field = evt.column.getColId()
    const structure = (evt.newValue === '' || evt.newValue == null) ? null : evt.newValue
    const mol = evt.data
    delete mol.serial
    delete mol['#']

    const transformObject = (
      obj: { [key: string]: string | number, id: string, Structure: string, customOrder: number },
    ) => {
      const {
        id, Structure, customOrder, ...properties
      } = obj
      return {
        properties,
        customOrder,
        id,
        structure: Structure,
      }
    }

    const transMol = transformObject(mol)

    if (field !== 'Structure') {
      addPendingChanges(transMol, structure, field)
    }

    try {
      if (field === 'Structure') {
        await fileStore.updateMoleculeStructure(mol, structure, [])

        StructureImageStore.renderStructure(
          structure,
          StructureImageStore.getRenderStructureType(structure),
          {
            highlightSubstructure: fileStore.queryStructure,
          },
        )
      } else {
        await onSaveChanges()
      }

      onUnActualSearchParamsNotice()
    } catch (err) {
      const loggingValue = field === 'Structure' ? 'structure' : evt.newValue
      message.error(`Cannot update value "${loggingValue}" for column "${field}"`)
      evt.node.updateData({
        ...evt.data,
        [field]: evt.oldValue,
      })
    }
  }, [onUnActualSearchParamsNotice, fileStore, onSaveChanges])

  const onSortChanged = useCallback(
    () => datasource.resetCache(),
    [datasource],
  )

  useEffect(() => {
    const reloadRows = (scrollTop: boolean, deselectRows: boolean, waitFor?: Promise<void>) => {
      datasource.resetCache(waitFor)
      gridApi?.purgeInfiniteCache()

      if (deselectRows) fileStore.setAllSelected(false)

      if (scrollTop) gridApi?.ensureIndexVisible(0, 'top')
    }

    const reloadRowsWithScrollAndDeselect = (waitFor?: Promise<void>) => reloadRows(true, true, waitFor)

    const addIndexProperties: (
      ...params: Parameters<Events['file:add-index-property']>
    ) => void = column => {
      if (column.defaultValue != null) {
        gridApi?.forEachNode(node => node.setData({
          ...node.data,
          [column.name]: column.defaultValue,
        }))

        // NOTE: we have to reset cache to fetch UPDATED data from API after new value added,
        // because old search results have no new field with defaultValue if it's provided
        datasource.resetCache()
      }
    }

    const unbind = [
      eventBusService.on('file:search', reloadRowsWithScrollAndDeselect),
      // NOTE: it's impossible to remove rows with InfiniteScroll model
      // via GridApi or RowApi
      eventBusService.on('file:delete-molecules', reloadRowsWithScrollAndDeselect),
      eventBusService.on('file:bulk-edit-molecules', reloadRowsWithScrollAndDeselect),
      eventBusService.on('file:add-index-property', addIndexProperties),
      // eventBusService.on('file:cache-eject', updateSelectedRows),
      subscribeBackgroundTask('background:completed', task => {
        if (task.type === 'bulk-edit') {
          reloadRowsWithScrollAndDeselect()
        }
      }),
    ]

    return () => unbind.forEach(off => off())
  }, [gridApi, datasource, fileStore, backgroundTasksStore])

  const renderBulkStructure = useCallback((renderedNodes: RowNode<MoleculeRow>[]) => {
    StructureImageStore.renderBulkStructure(
      renderedNodes.map(node => node.data?.Structure),
      fileStore.queryStructure,
    )
  }, [fileStore.queryStructure])

  const { run: onBodyScroll, cancel: cancelOnBodyScroll } = useDebounceFn(
    useCallback((event: BodyScrollEvent) => {
      if (event.type === 'horizontal') return
      renderBulkStructure(event.api.getRenderedNodes() as RowNode<MoleculeRow>[])
    }, [renderBulkStructure]),
    { wait: 300 },
  )

  const onModelUpdated = useCallback((event: ModelUpdatedEvent) => {
    cancelOnBodyScroll()
    renderBulkStructure(event.api.getRenderedNodes() as RowNode<MoleculeRow>[])
  }, [cancelOnBodyScroll, renderBulkStructure])

  const initialMoleculesCount = useMemo(() => fileStore.fileDescription?.moleculesLibraryCount, [fileStore])

  const infiniteInitialRowCount = useMemo(
    () => (!isNil(initialMoleculesCount)
      && (initialMoleculesCount < Number(window.SDFEConfig.REACT_APP_FILE_EDITOR_GRID_PAGE_SIZE))
      ? fileStore.fileDescription?.moleculesLibraryCount : datasource.aheadRows),
    [initialMoleculesCount, fileStore, datasource.aheadRows],
  )

  const [columnDefs, onColumnMoved] = useAgGridColDefs(
    fileStore.extendedPropertiesVisible,
    fileStore.indexProperties,
    fileStore.searchParams,
    fileStore.readOnly,
  )

  const context = {
    queryStructure: fileStore.queryStructure,
  }

  const processCellForClipboard = (params: ProcessCellForExportParams) => {
    const validCopy = params.value?.toString().replace(/\n/g, ' ')

    return validCopy
  }

  const processCellFromClipboard = (params: ProcessCellForExportParams) => {
    const oldValue = params.api.getValue(params.column as Column, params.node as RowNode)
    const { column } = params
    const columnType = column.getColDef().cellEditor
    const { value: newValue } = params
    const numericRegex = /^-?[0-9]*\.?[0-9]*$/

    if (columnType === 'numericCellEditor' && !numericRegex.test(newValue)) {
      onCellNotUpdate()
      return oldValue
    }

    return newValue
  }

  const getSelectedRangeData = (params: GetContextMenuItemsParams) => {
    const cellRanges = params.api.getCellRanges()

    if (fileStore.isAllSelected) {
      const selectedRows = getRowsFromDataGrid(gridApi, fileStore.loadedRowsCount)

      // eslint-disable-next-line no-underscore-dangle
      if (selectedRows?.length) {
        fileStore.setAllSelectedDataForExport(selectedRows)
      }
    }

    if (fileStore.allSelectedDataForExport?.length) {
      const dataForExport = prepareRowDataForExport(params, fileStore.allSelectedDataForExport)
      return dataForExport
    }

    let indexCounter = 1
    const rangeRowsData: { [key: number]: RangeRowData } = {}

    cellRanges?.forEach(range => {
      if (!range.startRow || !range.endRow) {
        return
      }

      const start = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
      const end = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)

      const rangeRows = Array.from({ length: end - start + 1 }, (_, i) => i + start)

      rangeRows.forEach(rowIndex => {
        const rowNode = params.api.getDisplayedRowAtIndex(rowIndex)

        // initialize rowData if not existent
        if (!rangeRowsData[rowIndex]) {
          rangeRowsData[rowIndex] = {
            '#': indexCounter,
            structure: '',
          }
          indexCounter += 1
        }

        // Fill rowData with selected cells
        range.columns.forEach(column => {
          const colId = column.getColId()
          rangeRowsData[rowIndex][colId] = rowNode?.data[colId]
        })

        // Always include "structure" column
        const structureCol = params.columnApi.getColumn('Structure')
        rangeRowsData[rowIndex].structure = rowNode?.data[structureCol?.getColId() || 'Structure']
      })
    })

    const rangeData = Object.values(rangeRowsData)
    return rangeData
  }

  const getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const items: (string | MenuItemDef)[] = params.defaultItems ?? []

    const filteredItems = items.filter(item => item !== 'export' && item !== 'copyWithGroupHeaders')

    const makeExportAction = (fileType: BookType) => () => {
      const selectedRangeData = getSelectedRangeData(params)
      backgroundTasksStore.createTask({
        type: 'data-export',
        data: {
          fileId: fileStore.fileId,
          fileName: fileStore.fileDescription!.fileName,
          formula: 'SMILES canonical',
          rangeData: selectedRangeData,
          fileType,
        },
      })
    }

    filteredItems.push({
      name: 'Export all currently selected data without structures',
      disabled: isDisabled,
      subMenu: [
        {
          name: 'Excel Export',
          action: makeExportAction('xlsx'),
          icon: '<span class="ag-icon ag-icon-excel"></span>',
        },
        {
          name: 'csvExport',
          action: makeExportAction('csv'),
          icon: '<span class="ag-icon ag-icon-csv"></span>',
        },
      ],
    })

    return filteredItems
  }

  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortingOrder: AG_GRID_TABLE_DEFAULT_SORTING_ORDER,
  }), [])

  return (
    <div className={cn('ag-grid', themeClassName)} style={{ height: parentHeight }}>
      <AgGridReact
        ref={gridRef}
        datasource={datasource}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        cacheBlockSize={datasource.pageSize}
        rowModelType="infinite"
        infiniteInitialRowCount={infiniteInitialRowCount}
        noRowsOverlayComponent="emptyFileOverlay"
        noRowsOverlayComponentParams={{
          type: noDataOverlayType,
        }}
        getRowId={getRowId}
        context={context}
        rowHeight={AG_GRID_TABLE_ROW_HEIGHT_PX}
        components={{
          columnHeader: AgGridColumnHeader,
          newColumnHeader: AgGridNewColumnHeader,
          lineNumberCellRenderer: FileLineNumberCell,
          lineNumberFieldHeader: FileLineNumberHeader,
          stringCellEditor: StringCellEditor,
          numericCellEditor: NumericalCellEditor,
          structureCellEditor: StructureCellEditor,
          structureRenderer: StructureRenderer,
          emptyFileOverlay: EmptyFileOverlay,
        }}
        suppressRowClickSelection
        rowSelection="multiple"
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        onColumnMoved={onColumnMoved}
        onSortChanged={onSortChanged}
        onModelUpdated={onModelUpdated}
        onBodyScroll={onBodyScroll}
        enableRangeSelection
        suppressCopyRowsToClipboard
        onRangeSelectionChanged={onRangeSelectionChanged}
        processCellFromClipboard={processCellFromClipboard}
        processCellForClipboard={processCellForClipboard}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
})

const FileEditorTableAgGrid: React.FC = observer(() => {
  const rootRef = useRef<HTMLDivElement | null>(null)
  // Height autocalc for parent 100% heightness
  const size = useSize(rootRef)

  return (
    <div ref={rootRef} className="h-full">
      {size.height && (
        <AgFileEditorTable
          parentHeight={size.height - 1}
        />
      )}
    </div>
  )
})

export default FileEditorTableAgGrid
