import {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import { Form, Input } from 'antd'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { match } from 'ts-pattern'

import { DeleteFilled, EyeFilled, EyeInvisibleOutlined } from '@ant-design/icons'
import CalculatedColumnHeader from '@components/common/CalculatedColumnHeader'
import DragHandle from '@components/icons/DragHandle'
import { useTableManagementStore } from '@contexts/file-edit-context'
import { columnNameValidator } from '@utils/validators'

interface IProps {
  indexProperty: IndexProperty
  isDragDisabled: boolean
}

const TableManagementColumnItem = observer(({ indexProperty, isDragDisabled }: IProps): ReactElement => {
  const tableManagementStore = useTableManagementStore()
  const [isEditName, setIsEditName] = useState(false)
  const [newName, setNewName] = useState(indexProperty.newName || indexProperty.name)
  const [validationError, setValidationError] = useState('')

  useEffect(() => {
    tableManagementStore.indexPropertyInEdit = isEditName ? indexProperty : null
  }, [isEditName, tableManagementStore, indexProperty])

  const commitEdit = useCallback(() => {
    const isNewNameTheSame = newName === indexProperty.name

    if (validationError) {
      setNewName(indexProperty.newName || indexProperty.name)
    } else if (isNewNameTheSame) {
      tableManagementStore.updateColumn(indexProperty, 'newName', undefined)
    } else {
      tableManagementStore.updateColumn(indexProperty, 'newName', newName)
    }

    setValidationError('')
    tableManagementStore.validationError = ''
    setIsEditName(false)
  }, [newName, indexProperty, tableManagementStore, validationError])

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setNewName(value)

    const validation = columnNameValidator(tableManagementStore, value)

    const errorMsg = match(validation)
      .with({ status: 'error' }, v => v.errorMsg)
      .with({ status: 'success' }, () => '')
      .exhaustive()

    setValidationError(errorMsg)
    tableManagementStore.validationError = errorMsg
  }, [tableManagementStore])

  const toggleDeleted = useCallback(() => {
    tableManagementStore.updateColumn(indexProperty, 'deleted', !indexProperty.deleted)
  }, [tableManagementStore, indexProperty])

  const toggleHidden = useCallback(() => {
    tableManagementStore.updateColumn(indexProperty, 'hidden', !indexProperty.hidden)
  }, [tableManagementStore, indexProperty])

  const name = indexProperty?.newName ?? indexProperty.name

  return (
    <div className="flex items-center mb-2 pr-3">
      <DragHandle
        className={cn('mr-2 text-gray-40 flex-shrink-0', { 'cursor-not-allowed': isDragDisabled })}
      />

      {isEditName ? (
        <Form
          style={{ color: 'inherit' }}
          requiredMark="optional"
          className="flex-grow"
        >
          <Form.Item
            validateFirst
            validateStatus={validationError ? 'error' : ''}
            className="mb-0"
          >
            <Input
              value={newName}
              autoFocus
              size="middle"
              style={{ height: 32 }}
              onBlur={commitEdit}
              onChange={onInputChange}
              onPressEnter={commitEdit}
            />
          </Form.Item>
        </Form>
      ) : (
        <div
          role="button"
          className={cn(
            'flex-grow truncate leading-8 rounded hover:bg-gray-10 dark:hover:bg-gray-60 pl-3',
            {
              'text-gray-40 dark:text-gray-50': indexProperty.hidden || indexProperty.deleted,
            },
          )}
          onClick={() => setIsEditName(true)}
        >
          <div className="flex justify-start items-center">
            {indexProperty.isCalculated && <CalculatedColumnHeader />}
            {name}
          </div>
        </div>
      )}

      <DeleteFilled
        onClick={toggleDeleted}
        className={cn(
          'ml-2',
          indexProperty.deleted ? 'text-danger' : 'text-gray-40',
        )}
      />

      {indexProperty.hidden
        ? (
          <EyeInvisibleOutlined onClick={toggleHidden} className="ml-2 text-gray-40" />
        )
        : (
          <EyeFilled onClick={toggleHidden} className="ml-2 text-gray-40" />
        )}
    </div>
  )
})

export default TableManagementColumnItem
