/* eslint-disable max-len */
import cn, { Argument } from 'classnames'
import { observer } from 'mobx-react'
import { match } from 'ts-pattern'

import MoleculeImg3 from '@assets/images/molecule-3.png'
import appStore from '@store/app'
import subscriptionStore from '@store/subscription'
import { currencySymbols, defaultCurrency } from '@shared/constants'
import PlanFunctionalityItem from './PlanFunctionalityItem'

interface PlanCardProps {
  plan: AcademicSubscriptionPlan
  header: number
  badge?: boolean
  className?: Argument
  buttons: JSX.Element
  theme?: typeof appStore.theme
}

type CurrentPlanStatus = Extract<SDFSubscriptionStatus, 'trialing' | 'active' | 'cancelled' | 'paused'>

interface BadgeProps {
  text: string
  className?: Argument
}

export const plansFunctionality: Record<AcademicPlanName, string[]> = {
  'Editor Academic': [
    'Store up to 1,000 structures',
    'Read SD files',
    'Filter and sort data',
    'Structure search',
    'Edit data one by one only',
    'Add and draw chemical structures with integrated Ketcher',
    'Export edits and search results to SD file format',
  ],
  'Library Academic': [
    'Store up to 10,000 structures',
    'Structure Search across all files with integrated Ketcher',
    'Create new files by merging selected files, or from search results',
    'Export selected records',
  ],
  'Knowledge Academic': [
    'Store up to 10,000,000 structures',
    'Auto-populate columns with molecular property value',
    'Edit data in bulk',
    'Add new rows and columns',
  ],
  Academic: [
    'Import SDF, SMILES Can & Iso, SMARTS, Mol',
    'Search/merge on structure',
    'Export in SDF, CSV and Excel',
    '‘Excel-like’',
    'Combine disparate data all at once',
    'Total Security',
    '10 million records managed',
    'Export in CSV, Excel & SDF formats',
    'Change attributes en-masse',
    'Cut & Paste multiple cells',
    'Accurate stereochemistry',
    'Ready-to-use & cloud-based',
    'De-duplication & federation on structure',
  ],
}

const gradientMap: Record<typeof appStore.theme, Record<number, string>> = {
  dark: {
    1: 'linear-gradient(201deg, #2C303E 20%, #23516B 90%)',
  },
  light: {
    1: 'linear-gradient(240deg, #81BDF5 20%, #6B86E7 90%)',
  },
}

const moleculeMap: Record<number, Record<string, string>> = {
  1: {
    src: MoleculeImg3,
    className: 'rotate-[-24deg] top-[60px] w-[120px] h-[88px] left-[205px]',
  },
}

const badgeMap: Record<CurrentPlanStatus, BadgeProps> = {
  trialing: {
    text: 'Free trial',
    className: 'bg-secondary-100',
  },
  active: {
    text: 'Current plan',
    className: 'bg-secondary-100',
  },
  paused: {
    text: 'Paused',
    className: 'bg-warning',
  },
  cancelled: {
    text: 'Canceled',
    className: 'bg-danger',
  },
}

const Badge = ({ text, className }: BadgeProps) => (
  <div className={cn('text-xs text-white py-0.5 rounded-xl', className)}>
    {text}
  </div>
)

const AcademicPlanCardView = ({
  plan, badge, header, className = '', buttons, theme = 'dark',
}: PlanCardProps): JSX.Element => {
  const { status, planId: currentPlanId } = subscriptionStore
  const isCurrentPlan = plan.id === currentPlanId
  let price = 0
  let currency = '$'
  let currencyIndex = 0

  for (let i = 0; i < defaultCurrency.length; i += 1) {
    if (plan.recurringPrices[defaultCurrency[i]] !== undefined) {
      price = plan.recurringPrices[defaultCurrency[i]]
      currency = currencySymbols[defaultCurrency[i]]
      currencyIndex = i
      break
    }
  }

  const billingCycleText = match(plan.billingType)
    .with('month', () => 'paid monthly')
    .with('year', () => `paid annually, ${currency}${plan.recurringPrices[defaultCurrency[currencyIndex]]} per year`)
    .exhaustive()

  return (
  // eslint-disable-next-line max-len
    <div
      className={cn('flex flex-shrink-0 bg-gray-10 dark:bg-gray-80 rounded-xl w-[995px] min-h-[345px] relative', className)}
    >
      {
        isCurrentPlan && match(status)
          .with('trialing', 'active', 'paused', 'cancelled', currentStatus => (
            <Badge
              text={badgeMap[currentStatus].text}
              className={cn('absolute top-[24px] right-[28px] -translate-y-1/2 px-3 font-bold', badgeMap[currentStatus].className)}
            />
          ))
          .otherwise(() => null)
        }
      <div
        className="relative text-white rounded-t-xl"

      >
        {
          badge && (
            <Badge
              // eslint-disable-next-line max-len
              className="absolute top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 text-sm text-white font-bold bg-secondary-100 px-5 py-0.5 rounded-xl"
              text="Popular"
            />
          )
        }
        <div
          className={cn('flex flex-col p-7 w-[350px] h-full rounded-l-xl')}
          style={{
            backgroundImage: gradientMap[theme][header],
          }}
        >
          <h2 className="text-xl font-bold">{plan.name}</h2>
          <div className="mt-1">
            <span className="text-4xl font-bold">{currency}{plan.billingType === 'year' ? Math.round(price / 12) : price}</span>
            <span className="text-sm"> /per month*</span>
          </div>
          <span className="text-xs">{billingCycleText}</span>
          {buttons}
        </div>
        <img
          src={moleculeMap[header].src}
          className={cn('absolute right-3 mix-blend-soft-light dark:mix-blend-overlay', moleculeMap[header].className)}
          alt="molecule"
        />
      </div>
      <div className="flex flex-col flex-grow py-9 pl-7 pr-4">
        {
          match(plan.name)
            .with('Editor Academic', () => (
              <div className="mb-4 font-semibold">
                Get the access to all tool features:
              </div>
            ))
            .with('Academic', () => (
              <div className="mb-4 font-semibold">
                Get the access to all tool features:
              </div>
            ))
            .with('Library Academic', () => (
              <div className="mb-4 font-semibold">
                Everything in Editor Edition, plus:
              </div>
            ))
            .with('Knowledge Academic', () => (
              <div className="mb-4 font-semibold">
                Everything in Library Edition, plus:
              </div>
            ))
            .exhaustive()
          }
        <div className={cn('flex flex-wrap justify-between gap-2')}>
          {
              plansFunctionality[plan.name].map(item => (
                <PlanFunctionalityItem
                  key={item}
                  className="mb-2 last:mb-0 w-[calc(50%-0.6rem)] box-border"
                >
                  {item}
                </PlanFunctionalityItem>
              ))
            }
        </div>
      </div>
    </div>
  )
}

export default observer(AcademicPlanCardView)
