import AddColumnTask from './tasks/add-column-task'
import BulkEditTask from './tasks/bulk-edit-task'
import DataExportTask from './tasks/data-export-task'
import ExportTask from './tasks/export-task'
import MergeTask from './tasks/merge-task'
import RemoveDuplicationsTask from './tasks/remove-duplicate-task'
import { BackgroundTask } from './types'

export const isAddColumnTask = (task: BackgroundTask): task is AddColumnTask => task.type === 'add-column'
export const isExportTask = (task: BackgroundTask): task is ExportTask => task.type === 'export'
export const isDataExportTask = (task: BackgroundTask): task is DataExportTask => task.type === 'data-export'
export const isMergeTask = (task: BackgroundTask): task is MergeTask => task.type === 'merge'
export const isBulkEditTask = (task: BackgroundTask): task is BulkEditTask => task.type === 'bulk-edit'
export const isRemoveDuplicationsTask = (
  task: BackgroundTask,
): task is RemoveDuplicationsTask => task.type === 'remove-duplications'

// eslint-disable-next-line max-len
export const isTaskRunning = (task: BackgroundTask): boolean => !(['Done', 'Aborted', 'Error'] as BackgroundTaskStatus[]).includes(task.status)

