/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FC, ReactElement, ReactNode, useCallback,
} from 'react'
import {
  Button, message, notification,
  Tooltip, Upload,
} from 'antd'
import cn from 'classnames'
import { RcFile, UploadRequestOption } from 'rc-upload/lib/interface'

import filesStore from '@store/files'
import subscriptionStore from '@store/subscription'
import { ExclamationCircleFilled } from '@ant-design/icons'
import CloudUpload from '@components/icons/CloudUpload'
import { ONE_MEGABYTE } from '@shared/constants'
import getHumanizedFileSize from '@utils/getHumanizedFileSize'

const { Dragger } = Upload

interface UploadMolFileButtonProps extends ElementProps {
  onGetMolfile: (molString: string) => void
}

interface UploadButtonProps extends ElementProps {
  disabled: boolean
  tooltipTitle?: ReactNode
}

interface UploadZoneProps {
  onDropCallback: () => void
  visible: boolean
}

const regexSdfExtension = /.sdf$/
const isSdfExtension = (fileName: string) => regexSdfExtension.test(fileName)

const isSdfExtensionCheckFailed = (fileName?: string): boolean => {
  if (fileName && isSdfExtension(fileName)) return false

  notification.warning({
    message: 'Invalid file type',
    description: 'Only .sdf is allowed',
    icon: <ExclamationCircleFilled />,
    duration: 5,
  })

  return true
}

const isFileSizeCheckFailed = (file: File) => {
  const { fileMaxSize } = subscriptionStore.subscriptionPlanSettings

  if (file.size > fileMaxSize) {
    notification.warning({
      message: 'Invalid file size',
      description: `Adroit REPOSITORY allows files up to ${getHumanizedFileSize({ bytes: fileMaxSize, hideDecimals: true })}. Please upload a smaller file`,
      icon: <ExclamationCircleFilled />,
      duration: 5,
    })

    return true
  }

  return false
}

const isSomeFileCheckFailed = (file: File) => isSdfExtensionCheckFailed(file.name) || isFileSizeCheckFailed(file)

const customRequest = (requestOptions: UploadRequestOption<any>) => {
  const file = requestOptions.file as RcFile

  if (isSomeFileCheckFailed(file)) return

  filesStore.startUploading(file as File)
}

const readFileContent = (file: File): Promise<string> => {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
    reader.readAsText(file)
  })
}

export const UploadMolFileButton = ({ className, onGetMolfile }: UploadMolFileButtonProps): ReactElement => {
  const customRequestMolFile = async ({ file }: any) => {
    const limitOfMegabytes = 5
    const sizeLimit = limitOfMegabytes * ONE_MEGABYTE

    if (file.size > sizeLimit) {
      message.error(`Maximum allowed file size is ${limitOfMegabytes}MB`)
      return
    }

    const fileContent = await readFileContent(file)

    onGetMolfile(fileContent)
  }

  return (
    <Upload
      accept=".mol"
      showUploadList={false}
      customRequest={customRequestMolFile}
    >
      <Button size="large" ghost className={cn(className)}>Upload .mol file</Button>
    </Upload>
  )
}

export const UploadButton = ({ className, disabled, tooltipTitle }: UploadButtonProps): ReactElement => (
  <Tooltip placement="left" title={tooltipTitle}>
    <Upload
      accept=".sdf"
      showUploadList={false}
      customRequest={customRequest}
    >
      <Button
        size="large"
        type="primary"
        className={cn(className)}
        disabled={disabled}
      >
        Upload SD file
      </Button>
    </Upload>
  </Tooltip>
)

const UploadZoneBackground = (): ReactElement => (
  <div className="py-12 text-sm w-full flex flex-col items-center">
    <div className="text-gray-30 dark:text-gray-40 flex flex-col items-center mb-4">
      <CloudUpload />
      <div className="mt-2">No Files</div>
    </div>

    <div>Drop file here or use Upload SD file button</div>
  </div>
)

export const UploadZone: FC<UploadZoneProps> = ({ onDropCallback, visible, children }) => {
  const onDrop = useCallback((e: React.DragEvent) => {
    const file = e.dataTransfer.files[0]

    isSdfExtensionCheckFailed(file?.name)
    onDropCallback()
  }, [onDropCallback])

  return (
    <div className="upload-zone">
      <Dragger
        accept=".sdf"
        showUploadList={false}
        disabled={!visible}
        className={cn({ '!border-none': !visible })}
        customRequest={customRequest}
        onDrop={onDrop}
      >
        {visible ? <UploadZoneBackground /> : children}
      </Dragger>
    </div>
  )
}
