import './assets/index.css'

import {
  ReactElement, useEffect, useState,
} from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { observer } from 'mobx-react'

import LogIn from '@pages/guest/LogIn'
import appStore from '@store/app'
import backgroundTasksStore from '@store/background-tasks'
import filesStore from '@store/files'
import subscriptionStore from '@store/subscription'
import userStore from '@store/user'
import authorizedRoutes from '@router/authorized-routes'
import guestRoutes from '@router/guest-routes'
import Cookies from '@components/common/Cookies'
import SpinnerArea from '@components/common/SpinnerArea'
import AppConfigProvider from '@components/hoc/AppConfigProvider'
import AppErrorBoundary from '@components/hoc/AppErrorBoundary'
import { BackgroundTasksStoreContext } from '@contexts/background-tasks'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { SENTRY_IGNORED_ERROR_LIST } from '@shared/constants'
import withSubscription from '@shared/hocs/withSubscription'
import useAttachTrackerScripts from '@shared/hooks/useAttachTrackerScripts'
import useRefetchParsingFiles from '@shared/hooks/useRefetchParsingFiles'
import useWatchFilesRecordLimitError from '@shared/hooks/useWatchFilesRecordLimitError'
import { showErrorMessage } from '@utils/messages'

if (window.SDFEConfig.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: window.SDFEConfig.REACT_APP_SENTRY_DSN,
    environment: window.SDFEConfig.REACT_APP_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: SENTRY_IGNORED_ERROR_LIST,
  })
}
const UnauthorizedZone = (): ReactElement => (
  <>
    {Object.values(guestRoutes).map(route => (
      <Route
        key={route.path}
        exact
        path={route.path}
        component={route.component}
      />
    ))}
    {Object.values(authorizedRoutes)
      .filter((value => value !== authorizedRoutes.Files))
      .map(route => (
        <Route
          key={route.path}
          exact
          path={route.path}
          component={LogIn}
        />
      ))}
  </>
)

const AuthorizedZone = (): ReactElement => {
  const [loading, setLoading] = useState(true)
  useRefetchParsingFiles()
  useWatchFilesRecordLimitError()
  useEffect(() => {
    appStore.startInactivityTimer()
    // TODO: add 404 page in case of error ?
    Promise.all([
      subscriptionStore.fetchSubscriptionDetails(),
      userStore.fetchAccountDetails(),
      backgroundTasksStore.restoreTasks(),
      userStore.fetchMarketingConsent(),
      appStore.getComponentsVersions(),
    ])
      .then(() => setLoading(false))
      .catch(err => showErrorMessage(err?.message))

    return () => {
      appStore.clearInactivityTimer()
      filesStore.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(
    () => {
      appStore.userActivityRegistry.register(backgroundTasksStore)

      return () => {
        appStore.userActivityRegistry.unregister(backgroundTasksStore)
        backgroundTasksStore.destroy()
      }
    },
    [],
  )
  if (loading) return <SpinnerArea className="h-full w-full" />

  return (
    <BackgroundTasksStoreContext.Provider value={backgroundTasksStore}>
      {Object.values(authorizedRoutes).map(route => (
        <Route
          key={route.path}
          exact
          path={route.path}
          component={withSubscription(subscriptionStore.status, route.component)}
        />
      ))}
    </BackgroundTasksStoreContext.Provider>
  )
}

const ObservedAuthorizedZone = observer(AuthorizedZone)
const ObservedUnauthorizedZone = observer(UnauthorizedZone)

const AppRouting = (): ReactElement => {
  useEffect(() => {
    appStore.themeAutoDetect()
  }, [])
  return (
    <>
      {!userStore.authorized ? <ObservedUnauthorizedZone /> : <ObservedAuthorizedZone />}
      <Cookies />
    </>
  )
}

const ObservedAppRouting = observer(AppRouting)

const App = (): ReactElement => {
  useAttachTrackerScripts()
  return (
    <AppConfigProvider>
      <AppErrorBoundary>
        <Router>
          <Switch>
            <ObservedAppRouting />
          </Switch>
        </Router>
      </AppErrorBoundary>
    </AppConfigProvider>
  )
}
export default App
