/* eslint-disable react/no-unescaped-entities */
import {
  FC,
  ReactNode,
  useCallback,
  useRef,
} from 'react'
import {
  GetMainMenuItemsParams, GridApi, MenuItemDef,
} from 'ag-grid-community'
import { Popconfirm } from 'antd'
import cn from 'classnames'

import ColumnMenuIcon from '@components/icons/ColumnMenu'
import { useFileStore, useTableManagementStore } from '@contexts/file-edit-context'

const MAX_DISPLAY_LENGTH = 25 // Maximum length before truncation

const isReactNodeString = (value: ReactNode): value is string => typeof value === 'string'

interface ColumnSubmenuProps {
  showColumnMenu?: (source: HTMLElement) => void
  api?: GridApi
  displayName: ReactNode
  isMenuVisible?: boolean
  popConfirmVisible: boolean
  setPopConfirmVisible: (visible: boolean) => void
}

const ColumnSubmenu: FC<ColumnSubmenuProps> = ({
  showColumnMenu, api, displayName, isMenuVisible, popConfirmVisible, setPopConfirmVisible,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  // const [ref, setRef] = useState<HTMLButtonElement | null>(null)
  const fileStore = useFileStore()

  // const se = useCallback((node: any) => setRef(node), [])

  const tableManagementStore = useTableManagementStore()

  const isDisplayNameWiderThanMax = isReactNodeString(displayName) && displayName.length > MAX_DISPLAY_LENGTH

  const truncatedDisplayName = isReactNodeString(displayName) && isDisplayNameWiderThanMax
    ? `${displayName.substring(0, MAX_DISPLAY_LENGTH)}...`
    : displayName

  const indexProperty = tableManagementStore.indexProperties.find(
    item => item.name === displayName,
  )

  const onDeleteClick = useCallback(() => {
    setPopConfirmVisible(true)
  }, [setPopConfirmVisible])

  const applyChanges = useCallback(async () => {
    if (indexProperty) {
      tableManagementStore.updateColumn(indexProperty, 'deleted', !indexProperty.deleted)
    }
    await fileStore.updateIndexProperties(tableManagementStore.indexProperties)
    setPopConfirmVisible(false)
  }, [fileStore, indexProperty, setPopConfirmVisible, tableManagementStore])

  const toggleHidden = useCallback(async () => {
    if (indexProperty) {
      tableManagementStore.updateColumn(indexProperty, 'hidden', !indexProperty.hidden)
    }

    await fileStore.updateIndexProperties(tableManagementStore.indexProperties)
  }, [indexProperty, fileStore, tableManagementStore])

  const getMainMenuItems = useCallback((params: GetMainMenuItemsParams): (string | MenuItemDef)[] => {
    if (params.column.getColDef().field !== 'Structure') {
      return [
        {
          name: 'Remove column',
          action: onDeleteClick,
          icon: '<span class="ag-icon ag-icon-cut"/>',
          cssClasses: ['custom-menu-item'],
          disabled: fileStore.readOnly,
        }, {
          name: 'Hide column',
          action: toggleHidden,
          icon: '<span class="ag-icon ag-icon-eye"/>',
          disabled: fileStore.readOnly,
        },
      ]
    }

    return []
  }, [fileStore.readOnly, onDeleteClick, toggleHidden])

  const onMenuClicked = () => {
    api?.setGetMainMenuItems(getMainMenuItems)

    if (buttonRef.current && showColumnMenu) {
      showColumnMenu(buttonRef.current)
    }
  }

  return (
    <Popconfirm
      title={(
        <div>
          Are you sure you want to delete the {isDisplayNameWiderThanMax ? <br /> : null}
          "{truncatedDisplayName}" column?
        </div>
      )}
      open={popConfirmVisible}
      onConfirm={applyChanges}
      onCancel={() => setPopConfirmVisible(false)}
      okType="primary"
      cancelButtonProps={{ ghost: true }}
      okText="Yes"
      cancelText="Cancel"
    >
      <span ref={buttonRef}>
        <button
          className={cn('submenu-container', 'group', { hidden: !isMenuVisible })}
          type="button"
          onClick={onMenuClicked}
        >
          <ColumnMenuIcon />
        </button>
      </span>
    </Popconfirm>
  )
}

export default ColumnSubmenu
