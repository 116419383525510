import {
  makeAutoObservable,
  runInAction,
} from 'mobx'

import subscriptionStore from '@store/subscription'
import apiService, { isApiCustomException, isAuthError, isErrorHasMessage } from '@shared/services/api-service'
import {
  changePassword,
  deleteAccount,
  fetchAccountDetails,
  updateAccountDetails,
} from '@utils/http/account'
import {
  confirmTemporary,
  createAnAccount,
  getToken,
  resendSignUpEmail,
  resetPassword,
  signUp,
  updatePassword,
} from '@utils/http/auth'

class UserStore {
  private _authorizing = false
  private _loadingAccountDetails = false
  private _authorized = JSON.parse(localStorage.getItem('authorized') ?? 'false')
  private _signUpData: SignUpData = {}
  private _resetPasswordData: ResetPasswordData = {}
  private _firstName = ''
  private _lastName = ''
  private _email = ''
  private _token: AuthToken = JSON.parse(localStorage.getItem('token')!) ?? { authToken: '' }
  private _marketingConsent: boolean | null = null
  private _termsOfServiceConsent: boolean | null = null

  constructor() {
    makeAutoObservable(this)
    apiService.authToken = this._token.authToken
    window.addEventListener('storage', () => {
      const token = JSON.parse(localStorage.getItem('token') || 'null')

      if (!token) this.unauthorize()
      if (token && token.authToken !== this._token.authToken) this.setAuthToken(token)
    })
  }

  setAuthToken(token: AuthToken): void {
    apiService.authToken = token.authToken
    localStorage.setItem('token', JSON.stringify(token))
    localStorage.setItem('authorized', 'true')
    this._token = token
    this._authorized = true
  }

  async authorize({ email, password }: AuthorizeData): Promise<void> {
    this._authorizing = true

    try {
      const token = await getToken({ email, password })
      this.setAuthToken(token)
    } finally {
      runInAction(() => {
        this._authorizing = false
      })
    }
  }

  async signUp(email: string): Promise<SignUpResult> {
    this._authorizing = true

    try {
      const result = await signUp(email)

      runInAction(() => {
        this._signUpData = {
          email,
          userExist: result.userExist,
        }
      })

      return result
    } finally {
      runInAction(() => {
        this._authorizing = false
      })
    }
  }

  async restoreSignUpFlowForUncreatedUser(email: string): Promise<void> {
    this._signUpData = {
      email,
      userExist: false,
    }
  }

  async resendSignUpEmail(): Promise<void> {
    if (this._signUpData.email) {
      await resendSignUpEmail(this._signUpData.email)
    }
  }

  async confirmTemporary(email: string, temporaryPassword: string): Promise<void> {
    const { session } = await confirmTemporary(email, temporaryPassword)

    runInAction(() => {
      this._signUpData.session = session
      this._signUpData.temporaryPassword = temporaryPassword
    })
  }

  async createAnAccount(createAnAccountData: CreateAnAccountData): Promise<void> {
    this._authorizing = true

    try {
      const token = await createAnAccount(createAnAccountData)
      this.signUpData = {}
      this.setAuthToken(token)
    } finally {
      runInAction(() => {
        this._authorizing = false
      })
    }
  }

  async resetPassword(email: string): Promise<void> {
    this._authorizing = true

    try {
      await resetPassword(email)

      runInAction(() => {
        this._resetPasswordData = {
          email,
        }
      })
    } finally {
      runInAction(() => {
        this._authorizing = false
      })
    }
  }

  async updatePassword(updatePasswordData: UpdatePasswordData): Promise<void> {
    this._authorizing = true

    try {
      await updatePassword(updatePasswordData)
    } finally {
      runInAction(() => {
        this._authorizing = false
      })
    }
  }

  async changePassword(data: { oldPassword: string, newPassword: string }): Promise<void> {
    try {
      await changePassword(data)
    } catch (error) {
      let message = 'UNKNOWN_ERROR'

      if (isErrorHasMessage(error) && error.message === 'Incorrect username or password.') {
        message = 'INCORRECT_PASSWORD'
      }

      throw new Error(message)
    }
  }

  async fetchAccountDetails(): Promise<void> {
    if (this._loadingAccountDetails) return

    this._loadingAccountDetails = true

    try {
      const { firstName, lastName, email } = await fetchAccountDetails()

      runInAction(() => {
        this._firstName = firstName
        this._lastName = lastName
        this._email = email
      })
    } catch (error) {
      if (isAuthError(error)) return

      throw error
    } finally {
      runInAction(() => {
        this._loadingAccountDetails = false
      })
    }
  }

  async updateAccountDetails(data: { firstName: string, lastName: string, email: string }) {
    try {
      await updateAccountDetails(data)

      runInAction(() => {
        this._firstName = data.firstName
        this._lastName = data.lastName
        this._email = data.email
      })
    } catch (error) {
      const message = 'UNKNOWN_ERROR'

      throw new Error(message)
    }
  }

  fetchMarketingConsent = async () => {
    try {
      const { marketingConsent } = await apiService.get<MarketingConsent>('/marketing-consent')

      runInAction(() => {
        this._marketingConsent = marketingConsent
      })
    } catch (error) {
      if (isApiCustomException(error, 'MarketingConsentNotFoundException')) {
        runInAction(() => {
          this._marketingConsent = null
        })
      } else {
        throw error
      }
    }
  }

  updateMarketingConsent = async (marketingConsent: boolean) => {
    await apiService.post('/marketing-consent', {
      json: {
        marketingConsent,
      },
      headers: {
        'Content-Type': apiService.contentTypePlainText, // HACK no response data
      },
    })

    runInAction(() => {
      this._marketingConsent = marketingConsent
    })
  }

  fetchTermsOfServiceConsent = async () => {
    try {
      const { consent } = await apiService.get<TermsOfServiceConsent>('/terms-of-service')

      runInAction(() => {
        this._termsOfServiceConsent = consent
      })
    } catch (error) {
      this._termsOfServiceConsent = null
    }
  }

  updateTermsOfServiceConsent = async (consent: boolean) => {
    await apiService.post('/terms-of-service', {
      json: {
        consent,
      },
      headers: {
        'Content-Type': apiService.contentTypePlainText, // HACK no response data
      },
    })

    runInAction(() => {
      this._termsOfServiceConsent = consent
    })
  }

  async deleteAccount(password: string): Promise<void> {
    try {
      await deleteAccount(password)
      this.unauthorize()
    } catch (error) {
      let message = 'UNKNOWN_ERROR'

      if (isErrorHasMessage(error) && error.message === `Password for user ${this._email} is not correct`) {
        message = 'INCORRECT_PASSWORD'
      } else if (isErrorHasMessage(error) && error.message === `cannot delete profile because ${this._email} has active subscription`) {
        message = 'SUBSCRIPTION_IS_ACTIVE'
      }

      throw new Error(message)
    }
  }

  async signOut(): Promise<void> {
    try {
      const Authorization = `Bearer ${this._token.authToken}`

      this.unauthorize()

      // createRequest instead direct post used for right handling of refreshToken feature
      await apiService.createRequest('/sign-out', {
        method: 'post',
        headers: {
          Authorization,
          'Content-Type': apiService.contentTypePlainText, // HACK no response data
        },
      })
    } catch (error) {
      if (isAuthError(error)) return

      throw error
    }
  }

  private unauthorize() {
    this._authorized = false
    this._token.authToken = ''
    this._loadingAccountDetails = false
    this.marketingConsent = null

    apiService.authToken = ''
    subscriptionStore.clearStatus()

    localStorage.removeItem('authorized')
    localStorage.removeItem('token')
  }

  get authorizing(): boolean {
    return this._authorizing
  }

  get authorized(): boolean {
    return this._authorized
  }

  get signUpData(): SignUpData {
    return this._signUpData
  }

  set signUpData(value: SignUpData) {
    this._signUpData = value
  }

  get resetPasswordData(): ResetPasswordData {
    return this._resetPasswordData
  }

  set resetPasswordData(value: ResetPasswordData) {
    this._resetPasswordData = value
  }

  get firstName(): string {
    return this._firstName
  }

  get lastName(): string {
    return this._lastName
  }

  get email() {
    return this._email
  }

  get loadingAccountDetails() {
    return this._loadingAccountDetails
  }

  get token() {
    return this._token
  }

  get hasMarketingConsentBeenCollected() {
    return this._marketingConsent !== null
  }

  set marketingConsent(consent: boolean | null) {
    this._marketingConsent = consent
  }

  get marketingConsent() {
    return this._marketingConsent
  }

  set termsOfServiceConsent(consent: boolean | null) {
    this._termsOfServiceConsent = consent
  }

  get termsOfServiceConsent() {
    return this._termsOfServiceConsent
  }
}

export default new UserStore()
