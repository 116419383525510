import { makeAutoObservable, observable, runInAction } from 'mobx'

import IndigoHttpService from '@utils/http/indigo'
import structureImagesCache from '@utils/structure-images-cache'

interface RenderStructureOptions extends QueryOptionsWithAbortController {
  highlightSubstructure?: string
}

type ImageErrors = { [key: string]: string }

class StructureImageStore {
  private readonly MolFormatRE = /\s+/
  private _imagesCache = new Map<string, string>()
  public imagesErrors: ImageErrors = {};

  constructor() {
    makeAutoObservable(this)
  }

  get imagesCache(): Map<string, string> {
    return observable.map(this._imagesCache)
  }

  set imagesCache(imagesCache) {
    this._imagesCache = new Map([...this._imagesCache, ...imagesCache])
  }

  async renderStructure(
    structure: string,
    type: RenderStructureType,
    {
      highlightSubstructure,
      abortController,
    }: RenderStructureOptions = {},
  ): Promise<string> {
    let svgAsText = structureImagesCache.get(this._imagesCache, structure, highlightSubstructure)

    const normalizedSvgAsText = svgAsText?.trim().replace(/\r?\n|\r/g, '')
    const normalizedReferenceSvg = '<?xml version="1.0" encoding="UTF-8"?>'
      // eslint-disable-next-line max-len
      + '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="120" height="60" viewBox="0 0 120 60"></svg>'

    if (svgAsText && normalizedSvgAsText !== normalizedReferenceSvg) {
      return svgAsText
    }

    const params = this.getIndigoRenderParams(structure, type, highlightSubstructure)

    svgAsText = await IndigoHttpService.renderMolString(
      params,
      { abortController },
    )

    runInAction(() => {
      structureImagesCache.set(this._imagesCache, structure, highlightSubstructure, svgAsText as string)
    })

    return svgAsText
  }

  async renderBulkStructure(
    structures?: (string | undefined)[],
    highlightSubstructure?: string,
  ): Promise<void> {
    const params: IndigoRenderParams[] = []

    structures?.forEach(structure => {
      if (structure) {
        const svgAsText = structureImagesCache.get(this._imagesCache, structure, highlightSubstructure)

        if (!svgAsText) {
          params.push(
            this.getIndigoRenderParams(
              structure,
              this.getRenderStructureType(structure),
              highlightSubstructure,
            ),
          )
        }
      }
    })

    if (!params.length) return

    const structureImages = await IndigoHttpService.renderBulkMolString(params)

    runInAction(() => {
      this.imagesCache = new Map(
        Object.entries(structureImages).map(structureImage => [
          structureImagesCache.getKey(structureImage[0], highlightSubstructure),
          atob(structureImage[1].result),
        ]),
      )
      const imagesErrors = Object.entries(structureImages).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value.error }),
        {},
      )

      if (Object.keys(this.imagesErrors).length < 100) {
        this.imagesErrors = {
          ...this.imagesErrors,
          ...imagesErrors,
        }
      } else {
        this.imagesErrors = imagesErrors
      }
    })
  }

  getRenderStructureType(structure: string): RenderStructureType {
    const isMolFormat = this.MolFormatRE.test(structure)
    return isMolFormat ? 'MOL' : 'RAW'
  }

  async deserializeStructure(
    structure: string,
    options?: QueryOptionsWithAbortController,
  ): Promise<string> {
    return IndigoHttpService.deserializeMolStruct(structure, options)
  }

  getIndigoRenderParams = (structure: string, type: RenderStructureType, highlightSubstructure?: string) => {
    const params: IndigoRenderParams = {
      structure: { value: structure, type },
      height: '60px',
      width: '120px',
    }

    if (highlightSubstructure) {
      params.query = { value: highlightSubstructure, type: 'MOL' }
    }

    return params
  }
}

export default new StructureImageStore()
