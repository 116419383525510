import {
  ReactElement, useCallback, useEffect, useMemo, useState,
} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Tabs } from 'antd'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'

import {
  BackgroundTask,
  isAddColumnTask,
  isBulkEditTask,
  isTaskRunning,
  subscribeBackgroundTask,
} from '@store/background-tasks'
import FileStore from '@store/file'
import filesStore from '@store/files'
import StructureImageStore from '@store/structure-image'
import tableFilteringStore from '@store/table-filtering'
import TableManagementStore from '@store/table-management'
import EditorFooter from '@components/common/EditorFooter'
import Header from '@components/common/Header'
import SimpleInnerDrawer from '@components/common/SimpleInnerDrawer'
import SpinnerArea from '@components/common/SpinnerArea'
import FileEditorPanel from '@components/file/FileEditorPanel'
import FileEditorTableAgGrid from '@components/file/FileEditorTableAgGrid'
import FilterResults from '@components/file/FilterResults'
import FiltersAmount from '@components/file/FiltersAmount'
import RemoveDuplicatesModal from '@components/file/RemoveDuplicatesModal'
import TableFiltering from '@components/file/TableFiltering'
import Chevron from '@components/icons/Chevron'
import StructureSearchPanel from '@components/structure-search/StructureSearchPanel'
import useBackgroundTasksContext from '@contexts/background-tasks'
import FileEditorProvider, {
  useFileStore,
  useTableManagementStore,
} from '@contexts/file-edit-context'
import { useKetcherContext } from '@contexts/ketcher-context'
import KetcherModalProvider from '@contexts/ketcher-provider'
import useUnActualSearchParamsNotice from '@shared/hooks/useUnActualSearchParamsNotice'
import AgGridApiService from '@shared/services/AgGridApiService'
import eventBusService from '@shared/services/event-bus-service'
import Logger from '@shared/services/logger'
import { getAmountTasksByFileId } from '@utils/file-store-utils'
import structureImagesCache from '@utils/structure-images-cache'

const { TabPane } = Tabs

const StructureTabPaneTitle = (): ReactElement => {
  const fileStore = useFileStore()

  return (
    <span className="inline-flex items-center">
      By structure
      <FiltersAmount filtersAmount={Number(fileStore.searchParams.type !== 'all')} />
    </span>
  )
}

const ObservedStructureTabPaneTitle = observer(StructureTabPaneTitle)

const AttributesTabPaneTitle = (): ReactElement => {
  const fileStore = useFileStore()

  return (
    <span className="inline-flex items-center">
      By attributes
      <FiltersAmount filtersAmount={Number(fileStore.searchParams.filters?.length ?? 0)} />
    </span>
  )
}

const ObservedAttributesTabPaneTitle = observer(AttributesTabPaneTitle)

const FileEditorPage = (): ReactElement => {
  const { state } = useLocation<{ previousPage?: string }>()
  const fileStore = useFileStore()
  const tableManagementStore = useTableManagementStore()
  const backgroundTasksStore = useBackgroundTasksContext()

  const [fileEditorIsReady, setFileEditorIsReady] = useState(false)
  const logger = useMemo(() => new Logger('FileEditorPage'), [])

  useEffect(() => {
    const unbindDeleteMolecules = eventBusService.on('file:delete-molecules', () => {
      fileStore.refetchFileDescription()
    })

    const init = async () => {
      await Promise.all([
        fileStore.fetchIndexProperties(),
        fileStore.setFileDescription(),
        fileStore.fetchUiSettings(),
      ])

      const useFilesStoreSearchParams = state?.previousPage === 'Files'
        && !!filesStore.getUploadedFileSearchStat(fileStore.fileId)

      if (useFilesStoreSearchParams) {
        fileStore.queryStructure = filesStore.searchParams.queryStructure || ''
        fileStore.isStructureSearchVisible = true
      }

      await tableManagementStore.getChemicalProperties()
      tableManagementStore.indexProperties = fileStore.indexProperties

      const filesStoreSearchParams = useFilesStoreSearchParams ? {
        queryStructure: filesStore.searchParams.queryStructure,
        type: filesStore.searchParams.type,
        similarity: filesStore.searchParams.similarity,
      } : {}

      const newFileStoreSearchParams = {
        ...fileStore.searchParams,
        ...filesStoreSearchParams,
      }

      fileStore.searchParams = newFileStoreSearchParams

      setFileEditorIsReady(true)
      logger.log('file editor is ready')

      fileStore.searchMolecules(newFileStoreSearchParams)

      if (newFileStoreSearchParams.filters || newFileStoreSearchParams.queryStructure) {
        fileStore.renderMatchedMolecules(newFileStoreSearchParams)
      }
    }

    init()

    return unbindDeleteMolecules
  }, [fileStore, logger, state, tableManagementStore])

  useEffect(
    () => () => {
      fileStore.abortStructureSearch()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => autorun(() => {
    tableManagementStore.indexProperties = fileStore.indexPropertiesWithoutNewColumn
  }), [tableManagementStore, fileStore])

  useEffect(() => {
    const amout: number | undefined = getAmountTasksByFileId(backgroundTasksStore.runningTasks).get(fileStore.fileId)
    fileStore.readOnly = (amout ?? 0) > 0 || fileStore.isMoleculesUpdating
  }, [backgroundTasksStore.runningTasks, fileStore, fileStore.isMoleculesUpdating])

  const onBulkEditSuccess = useUnActualSearchParamsNotice({
    refreshCallback: () => {
      fileStore.renderMatchedMolecules(fileStore.searchParams)
      fileStore.repeatSearchMolecules()
    },
    disabled: fileStore.isSearchParamsDefault,
  })

  useEffect(() => {
    const removeStartedListener = subscribeBackgroundTask('background:started', task => {
      if (isBulkEditTask(task)) {
        fileStore.isMoleculesUpdating = true
      }
    })

    const removeCompletedListener = subscribeBackgroundTask('background:completed', task => {
      if (isAddColumnTask(task)) {
        const newColumn = task.column

        if (newColumn && !task.formula) {
          fileStore.indexProperties = fileStore.indexProperties.map(indexProperty => {
            if (indexProperty.status === 'pending') return newColumn
            return indexProperty
          })
          fileStore.repeatSearchMolecules()
        } else {
          fileStore.deleteNewColumn()
          fileStore.fetchIndexProperties()
        }
      }

      if (isBulkEditTask(task)) {
        onBulkEditSuccess()
      }
    })

    const deleteNewColumn = (task: BackgroundTask) => {
      if (isAddColumnTask(task)) {
        fileStore.deleteNewColumn()
      }
    }

    const removeErrorListener = subscribeBackgroundTask('background:error', deleteNewColumn)
    const removeAbortListener = subscribeBackgroundTask('background:aborted', deleteNewColumn)

    const removeSettledListener = subscribeBackgroundTask('background:settled', task => {
      if (isBulkEditTask(task)) {
        fileStore.isMoleculesUpdating = false
      }
    })

    return () => {
      removeStartedListener()
      removeSettledListener()
      removeCompletedListener()
      removeErrorListener()
      removeAbortListener()
    }
  }, [backgroundTasksStore, fileStore, onBulkEditSuccess])

  useEffect(() => {
    const dispose = autorun(() => {
      const filesWithRunningBulkEdit = backgroundTasksStore.bulkEditTasks
        .filter(isTaskRunning)
        .reduce<string[]>((acc, el) => {
          acc.push(el.indexId)
          return acc
        }, [])

      const doesCurrentFileHasRunningBulkEdit = !!filesWithRunningBulkEdit.find(id => id === fileStore.fileId)

      if (doesCurrentFileHasRunningBulkEdit) {
        fileStore.isMoleculesUpdating = true
      }
    })

    return () => dispose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => () => {
    tableFilteringStore.resetFilters()
  }, [fileStore.fileId])
  const { clearModals } = useKetcherContext()

  const onSearchClick = useCallback(searchParams => {
    clearModals()
    fileStore.abortStructureSearch()
    fileStore.searchMolecules(searchParams)
    fileStore.renderMatchedMolecules(searchParams)
  }, [clearModals, fileStore])

  const toggleStructureSearchPanelVisibility = useCallback(() => {
    fileStore.toggleIsStructureSearchVisible()
  }, [fileStore])

  return (
    <div className="h-full flex flex-col">
      <Header fileStore={fileStore} />

      <div className="flex-grow flex">
        {(fileStore.fileMetaDataFetching || !fileEditorIsReady) ? (
          <SpinnerArea />
        ) : (
          <>
            <SimpleInnerDrawer visible={fileStore.isStructureSearchVisible}>
              <>
                <div className="flex justify-between items-center mb-3 text-sm">
                  <FilterResults showInactive />

                  <div
                    role="button"
                    onClick={toggleStructureSearchPanelVisibility}
                    className="bg-gray-10 dark:bg-gray-65 py-0.5 px-3 rounded-full"
                  >
                    <Chevron
                      className="transform rotate-90 text-primary dark:text-white !mt-0"
                    />
                  </div>
                </div>

                <Tabs
                  defaultActiveKey="structureTabPane"
                  tabPosition="top"
                  size="large"
                >
                  <TabPane
                    tab={<ObservedStructureTabPaneTitle />}
                    key="structureTabPane"
                  >
                    <StructureSearchPanel
                      queryStructure={fileStore.queryStructure}
                      isResetButtonDisabled={fileStore.isSearchParamsDefault || fileStore.readOnly}
                      isSearchButtonDisabled={fileStore.readOnly}
                      searchParams={fileStore.searchParams}
                      onSearchClick={onSearchClick}
                      onResetClick={onSearchClick}
                      onQueryStructureChange={queryStructure => { fileStore.queryStructure = queryStructure }}
                    />
                  </TabPane>
                  <TabPane
                    tab={<ObservedAttributesTabPaneTitle />}
                    key="attributesTabPane"
                  >
                    <TableFiltering />
                  </TabPane>
                </Tabs>
              </>
            </SimpleInnerDrawer>

            <div className="mx-5 my-4 flex-col-grow">
              <FileEditorPanel />

              <div className="flex-grow">
                <FileEditorTableAgGrid />
              </div>
              <EditorFooter />
            </div>
          </>
        )}
      </div>
      {backgroundTasksStore.removeDuplicatesTasks.map(task => (
        (!task.displayed && task?.status === 'Done') && <RemoveDuplicatesModal key={task.id} task={task} />
      ))}
    </div>
  )
}

const ObservedFileEditorPage = observer(FileEditorPage)

const FileEditorPageWrapper = (): ReactElement => {
  const { id } = useParams<{ id: string }>()
  const fileStore = new FileStore(id)
  const agGridApiService = useMemo(() => new AgGridApiService(), [])
  const tableManagementStore = useMemo(() => new TableManagementStore(), [])

  useEffect(() => () => agGridApiService.reset(), [agGridApiService])
  useEffect(() => () => structureImagesCache.clear(StructureImageStore.imagesCache), [id])

  return (
    <KetcherModalProvider>
      <FileEditorProvider stores={{ fileStore, agGridApiService, tableManagementStore }}>
        <ObservedFileEditorPage />
      </FileEditorProvider>
    </KetcherModalProvider>

  )
}

export default FileEditorPageWrapper
