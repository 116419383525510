import { ReactElement } from 'react'
import cn, { Argument } from 'classnames'

interface VATFootnoteProps {
  className?: Argument
}

const VATFootnote = ({ className = '' }: VATFootnoteProps): ReactElement => (
  <div className={cn(className)}>
    <span className="text-xs">Academic will require the institution
      is a bona fide not for profit research institution
    </span>
  </div>
)

export default VATFootnote
