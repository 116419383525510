import apiService from '@shared/services/api-service'

const fetchFiles = async (): Promise<UploadedFile[]> => apiService.get<UploadedFile[]>('/files')

const fetchFileDescription = async ({ id }: { id: string }): Promise<UploadedFile> => apiService.get<UploadedFile>(`/files/${id}`)

const deleteFile = async ({ id }: { id: string }): Promise<{ id: string }> => apiService.delete<{ id: string }>(`/upload/${id}`)

export {
  fetchFiles,
  fetchFileDescription,
  deleteFile,
}
